<!--
 * @Description: 房价
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:40:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-08-16 12:18:03
-->
<template>
  <el-card class="box">
    <el-row>
      <el-col :span="20">
        <el-form :inline="true" class="no-margin-bottom">
          <el-form-item label="日期">
            <el-date-picker v-model="currentDate" type="date" placeholder="选择开始日期" :clearable="false"
              :disabled-date="disabledDate"  @change="dateChange"></el-date-picker>
          </el-form-item>
          <el-form-item label="关键字">
            <el-select v-model="q" placeholder="房源名称" filterable clearable remote :remote-method="remoteHouse"
              @change="houseChange" style="width: 300px">
              <el-option :label="item.private_name" :value="item.id" v-for="item in houseList" :key="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房号">
        <el-input v-model="query.house_no" placeholder="房间号" style="width:200px;"  clearable  @input="houseNOChange"></el-input>
      </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4" class="flex-row flex-end">
        <batch-update></batch-update>
      </el-col>
    </el-row>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table class="price_table" ref="table" :data="tableData.data" border stripe>
      <el-table-column label="房源">
        <el-table-column label="ID" prop="house_id" width="80" fixed="left"></el-table-column>
        <el-table-column label="名称" fixed="left" width="280" :resizable="false">
          <template #default="scope">
            <!-- <div>内部{{ scope.row.private_name }}</div> -->
            <div>{{ scope.row.name }}</div>
            <div>
              <el-tag size="small" v-for="item in scope.row.house_no_tags" :key="item" style="margin-right: 5px;">{{ item }}</el-tag>
            </div>
          </template>

        </el-table-column>
      </el-table-column>
      <el-table-column v-for="col in columns" :key="col.id" width="100">
        <template #header>
          <div :class="'header' + (col.is_highlight ? ' light' : '')">
            <div class="date">{{ col.date_format }}</div>
            <div class="week">{{ col.week_text }}</div>
            <div :class="col.holiday ? 'holiday green' : 'holiday chinese'">
              {{ col.holiday ? col.holiday : col.chinese_date }}
            </div>
          </div>
        </template>
        <template #default="scope">
          <div class="price-item" @click="editPrice(scope.row, col, scope.row.data[col.uid])"
            v-if="scope.row.data[col.uid]">
            <div class="price">
              ￥{{ scope.row.data[col.uid].decimal_price }}
            </div>
            <div class="store">剩 {{ scope.row.data[col.uid].inventory }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="tableData.counts > 0" @size-change="pageSizeChange" @current-change="pageCurrentChange"
      :current-page="query.pageIndex" :page-size="query.pageSize" :page-sizes="[20, 40, 60, 80, 100]"
      layout="total,sizes, prev, pager, next" :total="tableData.counts">
    </el-pagination>
  </el-card>

  <el-dialog title="修改价格" v-model="dialogVisible" width="600px" :close-on-click-modal="false"
    :close-on-press-escape="false">
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item :label="`房源：${current.row.private_name}，日期：${current.col.date}`" prop="decimal_price">
        <el-input v-model="form.decimal_price" placeholder="请输入价格" size="large"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import common_api from "@/http/common_api";
import BatchUpdate from "./batch_update_price.vue";
export default {
  components: {
    BatchUpdate,
  },
  data() {
    return {
      loading: false,
      saving: false,
      columns: [],
      q: "",
      query: {
        q: "",
        pageIndex: 1,
        pageSize: 20,
      },
      houseList: [],
      tableData: {
        data: [],
        counts: 0,
      },
      //当前选择的日期
      currentDate: new Date(),
      // shortcuts: [
      //   {
      //     text: "Today",
      //     value: new Date(),
      //   },
      //   {
      //     text: "Yesterday",
      //     value: () => {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24);
      //       return date;
      //     },
      //   },
      //   {
      //     text: "A week ago",
      //     value: () => {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      //       return date;
      //     },
      //   },
      // ],
      //显示dialog
      dialogVisible: false,
      current: {},
      form: {},
      rules: {
        decimal_price: [
          {
            required: true,
            message: "必填价格",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadCalendar();
    this.remoteHouse("");
    this.loadData();
  },
  methods: {
    /**
     * 是否禁用日期
     */
    disabledDate(date) {
      var now = new Date();
      now.setDate(now.getDate() - 1);
      if (date < now) return true;
      now.setFullYear(now.getFullYear() + 1);
      if (date > now) return true;
    },
    /**
     * 日期选择改变
     * @param {*} date
     */
    dateChange() {
      this.loadCalendar();
      this.loadData();
    },
    /**
     * 关键字搜索楼盘下拉
     * @param {*} q
     */
    remoteHouse(q) {
      common_api.get_houses(q).then((res) => {
        if (res.code == 0) {
          this.houseList = res.data;
        }
      });
    },
    /**
     * 下拉楼盘选择
     * @param {*} id
     */
    houseChange(id) {
      this.query.house_id = id;
      this.loadData();
    },
    /**
     * 房号更改
     * @param {*} val 
     */
    houseNOChange(val){
        console.log(val);
        this.query.house_no = val;
        this.loadData();
    },

    /**
     * 加载日历列表
     */
    loadCalendar() {
      this.handleStartDate();
      this.columns = [];
      this.$http.post("seller/v1/housePrice/calendar", this.query).then((res) => {
        if (res.code == 0) {
          this.columns = res.data;
        }
      });
    },
    /**
     * 加载数据
     */
    loadData() {
      this.handleStartDate();
      this.loading = true;
      this.$http.post("seller/v1/housePrice/prices", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 处理开始日期
     */
    handleStartDate() {
      var year = this.currentDate.getFullYear();
      var month = this.currentDate.getMonth() + 1;
      var day = this.currentDate.getDate();
      this.query.start_date = `${year}-${month > 10 ? month : "0" + month}-${day > 10 ? day : "0" + day
        }`;
    },
    /**
     * 编辑修改价格
     */
    editPrice(row, col) {
      this.current = {
        row,
        col,
      };
      this.form = {
        house_id: row.house_id,
        date: col.date,
      };

      this.dialogVisible = true;
    },
    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http
            .post("seller/v1/house/update-price", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.current.row.data[this.current.col.uid].decimal_price =
                  this.form.decimal_price;
                this.current.row.data[this.current.col.uid].price =
                  this.form.price;
                this.dialogVisible = false;
              }
            });
        }
      });
    },
  },
};
</script>
<style>
.price_table.el-table .cell {
  /* height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; */
}
</style>
<style scoped>
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header .week,
.header .holiday {
  font-weight: normal;
}

.header.light .week,
.header.light .date {
  color: var(--el-color-danger);
}

.header .holiday.green {
  color: var(--el-color-success);
}

.price-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.price-item .store {
  font-size: 12px;
  color: #999;
}
</style>
